import React, {useEffect, useState} from 'react';
import {
    CaretDownOutlined,
    CaretRightOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    PlusOutlined
} from "@ant-design/icons";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextArea from "../../assessment/inputtypes/TextArea";
import {withUser} from "../../../../context/UserContext";
import {Rest} from "../../../../util/rest";
import toast from "../../../../util/toast";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import AssessmentTemplateQuestionV2 from "./AssessmentTemplateQuestionV2";
import _ from "lodash";

const inputLabelProps = {
    classes: {
        root: 'textField',
        focused: 'focused'
    },
};

function AssessmentTemplateGroupV2(props) {

    const [group, setGroup] = useState(props.group);
    const [editGroup, setEditGroup] = useState(false);
    const [showQuestions, setShowQuestions] = useState(true);
    const [newGroup, setNewGroup] = useState(props.group ? props.group.id === 0 : false);
    const [displayInfo, setDisplayInfo] = useState(props.group.displayInfo);
    const [aboutToDelete, setAboutToDelete] = useState(false);
    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        if(props.group && props.group.id === 0) {
            save();
            setEditGroup(true);
        }
    }, [props.group]);

    useEffect(() => {
        if(group.displayInfo !== displayInfo) {
            setGroup({...group,displayInfo: displayInfo});
            save();
        }
    }, [displayInfo]);

    useEffect(() => {
        if(props.onGroupChange) {
            props.onGroupChange(newGroup && group.id > 0 ? 0 : group.id, group);
        }
        if(newGroup && group.id > 0)
            setNewGroup(false);
        if(!_.isEqual(group, props.group))
            save();
    }, [group])

    function deleteGroup() {

        const originalId = group.id;
        Rest.authFetch(props.user, `/rest/assessment/templates/group/${group.id}`, {
            method: "DELETE"
        })
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        toast.error("Unauthorized!")
                    }
                    else if(props.onDelete) {
                        props.onDelete(originalId);
                        setAboutToDelete(false);
                    }
                });
    }

    function addQuestion() {
        const newQuestion = {
            id: 0,
            templateId: props.template.id,
            question: "",
            questionType: "FREE_TEXT",
            order: group.questions.length + 1,
            required: true,
            weight: 0,
            min: 0,
            max: 5,
            steps: 1,
            answers: [],
            dependencies: [],
            groupId: group.id
        }
        const questions = group.questions;
        questions.push(newQuestion)
        setGroup({...group,questions: questions});
    }

    function save() {
        if(!props.readOnly)

            Rest.authFetch(props.user, "/rest/assessment/templates/group", {
                method: "PUT",
                body: JSON.stringify(group)
            })
                    .then(response => {
                        if(response) {
                            setGroup(response);
                            if(props.onGroupChange)
                                props.onGroupChange(response);
                        }
                        else {
                            toast.error("Error saving Group");
                        }
                    });
    }

    function reOrderQuestions(questions) {
        questions?.sort((a, b) => (a.order > b.order) ? 1 : -1).forEach((question, index) => { //fix order when deleting a question in the middle
            question.order = index + 1;
        })
        return questions;
    }

    function onQuestionUpdate(originalId, question) {
        const questions = group.questions;
        const questionIndex = questions.findIndex(q => q.id === originalId);
        if(questionIndex >= 0) {
            questions[questionIndex] = question;
            setGroup({...group, questions:questions})
        }
    }

    function onQuestionDelete(originalId) {
        const questions = group.questions;
        const questionIndex = questions.findIndex(q => q.id === originalId);
        if(questionIndex >= 0) {
            questions.splice(questionIndex, 1);
            setGroup({...group,questions:reOrderQuestions(questions)})
            save();
        }
    }

    return (
            <>
                <>{collapsed ?
                   <div className={"groups"}>
                       <div className={"row p-2"}>
                           <div className={"col-11"}>
                               <CaretRightOutlined className="icon" onClick={() => {setCollapsed(false)}}/>
                               <span className="group-name">{group.name}</span>
                           </div>
                           <div className={"col-1 text-end align-items-end"}>
                               <span className="questions-qty">{group.questions.length}</span>
                           </div>
                       </div>
                   </div> :

                   <div className={"groups"}>
                       <div className="groups-control">
                           <div className="row">
                               <div className={"col-8"}>
                                   <CaretDownOutlined className="icon" onClick={() => {setCollapsed(true)}}/>
                                   {!editGroup &&
                                    <span className="group-name"
                                          onClick={() => setEditGroup(!props.readOnly)}>{group.name}</span>}
                               </div>
                               <div className={"col-4 text-end my-auto"}>
                                   {!props.readOnly ?
                                    <>
                                        <Button variant="outlined"
                                                size="small"
                                                title="Edit group info"
                                                style={{marginRight: "3px"}}
                                                onClick={() => setEditGroup(!editGroup)}
                                                endIcon={<EditOutlined className="icon"/>}>{editGroup ? "End " : ""}Edit
                                            Group</Button>
                                        <Button variant="outlined"
                                                size="small"
                                                title="Delete group"
                                                onClick={() => setAboutToDelete(true)}
                                                endIcon={<DeleteOutlined className="icon"/>}>Delete Group</Button>
                                    </> :
                                    <>
                                        <Button variant="outlined"
                                                size="small"
                                                title="Edit group info"
                                                style={{marginRight: "3px"}}
                                                onClick={() => setEditGroup(!editGroup)}
                                                endIcon={<EyeOutlined className="icon"/>}>{editGroup ? "End " : ""}Show
                                            Group Info</Button>
                                    </>
                                   }
                               </div>
                           </div>

                           <div className="row p-1">
                               {editGroup &&
                                <>
                                    <div className="col-6">
                                        <TextField
                                                fullWidth
                                                margin="dense"
                                                name="groupName"
                                                defaultValue={group.name}

                                                onChange={(e) => setGroup({...group, name: e.target.value})}
                                                label="Group name"
                                                InputLabelProps={inputLabelProps}
                                                className="textField"
                                                variant="outlined"
                                                onBlur={() => save()}


                                        />
                                    </div>
                                    <div className={"col-sm-2"}>
                                        <TextField
                                                fullWidth
                                                margin="dense"
                                                name="weight"
                                                defaultValue={group.weight}
                                                onChange={(e) => setGroup({...group, weight: e.target.value})}
                                                label="Group Weight"
                                                type="number"
                                                InputLabelProps={inputLabelProps}
                                                className="textField"
                                                variant="outlined"
                                                onBlur={() => save()}


                                        />
                                    </div>
                                    <div className={"col-sm-2"}>
                                        <TextField
                                                fullWidth
                                                margin="dense"
                                                name="order"
                                                defaultValue={group.order}
                                                onChange={(e) => setGroup({...group, order: e.target.value})}
                                                label="Group Order"
                                                type="number"
                                                InputLabelProps={inputLabelProps}
                                                className="textField"
                                                variant="outlined"
                                                onBlur={() => save()}
                                        />
                                    </div>

                                </>

                               }
                           </div>
                           {editGroup &&
                            <>
                                <div className="row p-1">
                                    <div className="col">
                                        <FormControlLabel
                                                classes={{root: 'FormControlLabel'}}
                                                label={"Display Group Instructions"}
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                            classes={{root: 'Checkbox', checked: "checked"}}
                                                            name={"displayInfo"}
                                                            checked={displayInfo}

                                                            onChange={(e) => {setDisplayInfo(e.target.checked);}}
                                                    />
                                                }
                                        />
                                    </div>
                                </div>

                                <div className="row p-1">
                                    <div className="col-md-6">
                                        <TextArea
                                                autofocus={false}
                                                label={"Group Description"}
                                                name={"description"}
                                                defaultValue={group.description}
                                                onChange={(e) => setGroup({...group, description: e.target.value})}
                                                onBlur={() => save()}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextArea
                                                autofocus={false}
                                                label={"Group Instructions"}
                                                name={"instructions"}
                                                defaultValue={group.instructions}
                                                onChange={(e) => setGroup({...group, instructions: e.target.value})}
                                                onBlur={() => save()}
                                        />
                                    </div>
                                </div>


                            </>
                           }
                           <div className={"row mt-3 pt-2 pb-1"} style={{borderTop: "solid 1px"}}>
                               <div className={"col-8"}>
                                   {showQuestions ?
                                    <CaretDownOutlined className="icon" onClick={() => {setShowQuestions(false)}}/> :
                                    <CaretRightOutlined className="icon" onClick={() => {setShowQuestions(true)}}/>
                                   }
                                   <span className={"question-control-label"}>Questions</span>
                               </div>
                               <div className={"col-4 text-end my-auto"}>
                                   <Button variant="outlined" size="small"
                                           endIcon={<PlusOutlined className="mx-auto icon"/>}
                                           onClick={() => {addQuestion()}}>Add Question</Button>
                               </div>
                           </div>
                       </div>
                       {showQuestions &&
                        <div className="questions">
                            <Droppable droppableId={group.id.toString()}>
                                {(provided) =>
                                        <div ref={provided.innerRef} className="scrollable-questions">
                                            {group.questions.sort((a, b) => (a.order > b.order) ? 1 : -1).map((question, index) => (
                                                    <Draggable key={question.id}
                                                               draggableId={question.id.toString()}
                                                               index={index}
                                                               isDragDisabled={props.readOnly}>
                                                        {(provided) =>
                                                                <div
                                                                        ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <AssessmentTemplateQuestionV2
                                                                            index={index}
                                                                            key={question.id}
                                                                            dependencyOptions={group.questions.map((q) => ({
                                                                                id: q.id,
                                                                                order: q.order,
                                                                                question: q.question,
                                                                                questionType: question.questionType,
                                                                                group: group.name
                                                                            }))}
                                                                            question={question}
                                                                            onChange={onQuestionUpdate}
                                                                            onDelete={onQuestionDelete}
                                                                            readOnly={props.readOnly}
                                                                    />
                                                                </div>
                                                        }
                                                    </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                }
                            </Droppable>
                        </div>
                       }
                   </div>}
                </>
                <div>
                    <Dialog open={aboutToDelete} aria-labelledby="form-dialog-title"
                            // onClose={(event,reason)=>{
                            //     if(reason !== 'backdropClick' && reason !== 'escapeKeyDown')
                            //         setShowAlert(false);
                            // }}
                            disableBackdropClick
                            disableEscapeKeyDown
                            className="pcmh-content-uploader">
                        <DialogTitle title="Delete Group" classes={{root: "editorHeader"}}>Delete Group</DialogTitle>
                        <DialogContent>
                            <span className={"warning-text"}>Warning! Deleting group will delete all associated questions.</span>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {setAboutToDelete(false);}} variant="contained"
                                    className="editor-button">Cancel</Button>
                            <Button onClick={() => {deleteGroup();}} variant="contained"
                                    className="editor-button">Delete</Button>
                        </DialogActions>
                    </Dialog>
                </div>

            </>);
}

export default withUser(AssessmentTemplateGroupV2);