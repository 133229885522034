import React, {useContext, useEffect, useReducer, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    ListSubheader,
    MenuItem,
    Select,
    TextField,
    Checkbox
} from "@mui/material";
import {TemplateContext} from "./AssessmentTemplateEditorV2";
import './AssessmentTemplateEditorV2.css';

const OPERATORS = ["EQUALS", "NOT", "GREATER", "LESS", "GREATER_OR_EQUAL", "LESS_OR_EQUAL", "CONTAINS", "STARTS_WITH", "ENDS_WITH"];
const AssessmentTemplateQuestionDependencyEditor = (props) => {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const template = useContext(TemplateContext);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [questions, setQuestions] = React.useState([]);
    const [selectedDependencyQuestionId, setSelectedDependencyQuestionId] = React.useState("");
    const [selectedAnswerId, setSelectedAnswerId] = React.useState("");
    const [answers, setAnswers] = React.useState([]);
    const [dependencyId, setDependencyId] = React.useState(0);
    const [selectedFreeText, setSelectedFreeText] = React.useState("");
    const [selectedDate, setSelectedDate] = React.useState("");
    const [selectedNumber, setSelectedNumber] = React.useState(0);
    const [operator, setOperator] = React.useState("EQUALS");
    const [not, setNot] = React.useState(false);
    
    const isValidQuestion = (q) => q.id !== props.question.id && ((["SELECTION", "FREE_TEXT" ,"NUMERIC_VALUE" , "RANGE", "DATE"].includes(q.questionType)));

    useEffect(() => {
        if(props.question.dependencies?.length > 0) {
            setDependencyId(props.question.dependencies[0].id);
            setSelectedDependencyQuestionId(props.question.dependencies[0].assessmentTemplateDependentQuestionId);
            setSelectedAnswerId(props.question.dependencies[0].assessmentTemplateAnswerId);
            setSelectedFreeText(props.question.dependencies[0].answerText);
            setOperator(props.question.dependencies[0].operator==="EQUALS" && props.question.dependencies[0].not?"NOT":props.question.dependencies[0].operator);
            setSelectedNumber(props.question.dependencies[0].answerNumeric);
            setNot(props.question.dependencies[0].not || false);
            forceUpdate();
        }
        else {
            clear();
        }
    }, [props.question, props.open]);

    const clear = () => {
        setSelectedAnswerId("");
        setSelectedDependencyQuestionId("");
        setDependencyId(0);
        setSelectedQuestion(null);
        setSelectedFreeText("");
        setSelectedNumber(0);
        setOperator("EQUALS");
        setNot(false);
    }
    useEffect(() => {
        if(template) generateFlattenQuestions(template.groups);
    }, [template]);

    const isEnabled = () => {
        if(props.readOnly || selectedDependencyQuestionId === "" || selectedQuestion === null || selectedQuestion === undefined) return false;
        switch(selectedQuestion.questionType) {
            case "SELECTION":
                return selectedAnswerId !== ""
            case "DATE":
            case "FREE_TEXT":
                return selectedFreeText !== ""
            case "RANGE":
            case "NUMERIC_VALUE":
                return OPERATORS.includes(operator) && selectedNumber;
            default:
                return false;
        }
    }

    const onNumericChange = (value) => {
        if(value >= selectedQuestion?.min && value <= selectedQuestion?.max) {
            setSelectedNumber(value);
        }
    }

    useEffect(() => {
        setAnswers([]);
        setSelectedFreeText("");
        setSelectedNumber(0);
        setSelectedAnswerId("");
        const question = questions.find((question) => question.id === selectedDependencyQuestionId);
        if(question?.answers) {
            setAnswers(question.answers);
        }
        setSelectedQuestion(question);
    }, [selectedDependencyQuestionId]);


    useEffect(() => {
        console.log(not);
    }, [not]);
    const generateFlattenQuestions = (groups) => {
        if(!groups || groups.length === 0) setQuestions([]);
        const localQuestions = [];
        let lastGroup = null;
        groups.forEach(group => {
            if(lastGroup !== group.name) {
                lastGroup = group.name;
                localQuestions.push({
                                        id: group.id,
                                        group: group.name,
                                        groupOrder: group.order,
                                        groupId: group.id,
                                        isGroup: true
                                    });
            }
            group.questions?.filter(isValidQuestion).forEach(q => {
                localQuestions.push(
                        {
                            id: q.id,
                            order: q.order,
                            groupOrder: group.order,
                            question: q.question,
                            questionType: q.questionType,
                            group: group.name,
                            groupId: group.id,
                            answers: q.answers,
                            max: q.max,
                            min: q.min,
                            steps: q.steps,
                            isGroup: false
                        });
            })

        });
        setQuestions(localQuestions);
    }

    const saveDependencies = (remove) => {
        if(remove) {
            props.handleDependencySelected([]);
            clear()
        }
        else
            props.handleDependencySelected([{
                dependencyId: dependencyId,
                dependentQuestionId: parseInt(selectedDependencyQuestionId),
                dependentAnswerId: parseInt(selectedAnswerId),
                dependentTextAnswer: selectedFreeText,
                dependentNumericAnswer: selectedNumber,
                dependentDateAnswer: selectedDate,
                dependentOperator: operator!=="NOT"?operator:"EQUALS",
                dependentNot: not
            }])
    }

    return (
            <Dialog open={props.open}>
                <DialogTitle title={props.title} classes={{root: "editorHeader"}}>
                    Dependency Editor
                    <div className="current-question">
                        {props.question.question}
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="dependency-editor-contents">
                        <div className="prompt">
                            Show this question if the following question:
                        </div>
                        <div className="list-of-questions" style={{width: '100%'}}>

                            <Select onChange={(e) => setSelectedDependencyQuestionId(e.target.value)}
                                    disabled={props.readOnly}
                                    className="dependency-editor-select"
                                    variant="outlined"
                                    value={selectedDependencyQuestionId}>
                                <MenuItem value="" disabled>
                                    Select a question...
                                </MenuItem>
                                {questions.map((option) => {
                                    if(option.isGroup) {
                                        return (
                                                <ListSubheader key={option.groupId}
                                                               sx={{
                                                                   fontSize: "1.3em",
                                                                   color: "var(--smartpcmh-text-color)",
                                                               }}
                                                >
                                                    {option.group}
                                                </ListSubheader>);
                                    }
                                    else {
                                        return (<MenuItem
                                                key={option.id}
                                                value={option.id}
                                        >
                                            {option.order + ". " + option.question}
                                        </MenuItem>);
                                    }
                                })};
                            </Select>
                        </div>
                        <div className="prompt">
                            Has the following answer:
                        </div>
                        {selectedQuestion?.questionType === "SELECTION" &&
                         <div>
                             <Select
                                     className="dependency-editor-select"
                                     value={selectedAnswerId}
                                     onChange={(e) => setSelectedAnswerId(e.target.value)}
                                     disabled={props.readOnly}
                                     variant="outlined">
                                 <MenuItem value="" disabled key={-1}>
                                     Select an answer...
                                 </MenuItem>
                                 {answers?.map((answer) => {
                                     return <MenuItem
                                             sx={{
                                                 marginLeft: "1em",
                                                 whiteSpace: "nowrap", // Prevent text from wrapping
                                                 overflow: "hidden",   // Hide overflowing content
                                                 textOverflow: "ellipsis", // Show ellipsis for long text
                                                 maxWidth: "100%",    // Restrict width to container
                                                 display: "block",
                                             }}

                                             value={answer.id} key={answer.id}>{answer.answer}</MenuItem>
                                 })}
                             </Select>
                         </div>
                        }
                        {selectedQuestion?.questionType === "FREE_TEXT" &&
                         <div>
                             <div className="row">
                                 <div className="col-2">
                                     <FormControlLabel 
                                             control={<Checkbox checked={not || false}
                                                                onChange={(e)=>setNot(e.target.checked || false)}
                                                                disabled={props.readOnly}/>} label="NOT" />
                                 </div>
                                 <div className="col-10">

                                     <Select
                                             variant="outlined"
                                             disabled={props.readOnly}
                                             fullWidth
                                             size="small"
                                             value={operator}
                                             onChange={(e) => setOperator(e.target.value)}>
                                         >
                                         <MenuItem value="EQUALS">Equals</MenuItem>
                                         <MenuItem value="STARTS_WITH">Starts with</MenuItem>
                                         <MenuItem value="ENDS_WITH">Ends with</MenuItem>
                                         <MenuItem value="CONTAINS">Contains</MenuItem>
                                     </Select>
                                 </div>
                             </div>
                             <div className="row" style={{ marginTop: "10px"}}>
                                 <div className="col-12">
                                     <TextField className="dependency-editor-text-field"
                                                size="small"
                                                value={selectedFreeText}
                                                disabled={props.readOnly}
                                                onChange={(e) => setSelectedFreeText(e.target.value)}/>
                                 </div>
                             </div>
                         </div>}
                        {(["NUMERIC_VALUE","RANGE","DATE"].includes(selectedQuestion?.questionType)) &&
                         <div>
                             {/*EQUALS, GREATER, LESS, GREATER_OR_EQUAL, LESS_OR_EQUAL, NOT, CONTAINS, STARTS_WITH, ENDS_WITH*/}
                             <div className="row">
                                 <div className="col-4">
                                     <Select variant="outlined"
                                             disabled={props.readOnly}
                                             fullWidth
                                             size="small"
                                             value={operator}
                                             onChange={(e) => {
                                                 setOperator(e.target.value)
                                                 if(e.target.value ==="NOT") {
                                                     setNot(true);
                                                 }
                                                 else {
                                                     setNot(false);
                                                 }
                                             }}>

                                         <MenuItem value="EQUALS">=</MenuItem>
                                         <MenuItem value="GREATER">&gt;</MenuItem>
                                         <MenuItem value="LESS">&lt;</MenuItem>
                                         <MenuItem value="GREATER_OR_EQUAL">&gt;=</MenuItem>
                                         <MenuItem value="LESS_OR_EQUAL">&lt;=</MenuItem>
                                         <MenuItem value="NOT">&lt;&gt;</MenuItem>
                                     </Select>
                                 </div>
                                 {  (selectedQuestion?.questionType === "NUMERIC_VALUE" || selectedQuestion?.questionType === "RANGE") &&
                                 <div className="col-8">
                                     <TextField className="dependency-editor-text-field"
                                                disabled={props.readOnly}
                                                type="number"
                                                size="small"
                                                fullWidth
                                                value={selectedNumber}
                                                onChange={(e) => onNumericChange(e.target.value)}/>
                                 </div>
                                 }
                                 {  (selectedQuestion?.questionType === "DATE") &&
                                 <div className="col-8">
                                     <TextField className="dependency-editor-text-field"
                                                disabled={props.readOnly}
                                                type="date"
                                                size="small"
                                                fullWidth
                                                value={selectedDate}
                                                onChange={(e) => {setSelectedFreeText(e.target.value); setSelectedDate(e.target.value)}}/>
                                 </div>
                                 }
                             </div>
                         </div>}

                    </div>
                </DialogContent>
                <DialogActions className="dependency-buttons">
                    <Button onClick={() => props.onClose(false)} variant="outlined">Cancel</Button>
                    {!props.readOnly &&
                     <>
                         <Button onClick={() => {
                             saveDependencies(true);
                             props.onClose(false)
                         }} 
                                 variant="outlined"
                                 disabled={!isEnabled()}>Remove
                             Dependency</Button>
                         <Button onClick={() => {
                             saveDependencies(false);
                             props.onClose(false)
                         }} 
                                 variant="outlined"
                                 disabled={!isEnabled()}>Save</Button>
                     </>
                    }
                </DialogActions>
            </Dialog>
    )
}
export default AssessmentTemplateQuestionDependencyEditor;